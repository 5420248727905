import React from 'react'
import FaviconIcon from '../images/about_shape.png'
import FlutterAppDevelopmentServiceBanner from '../images/flutter_service_banner.svg'
import FlutterAppDevelopmentServiceFeature from '../images/flutter_service_features.svg'
import AndroidServiceShapeone from '../images/service_banner_shape.png'
import AndroidServiceShapeTwo from '../images/service_banner_shape_2.png'
import ServiceCircleShape from '../images/half_circle_service_page.png'
import Layout from '../components/layout'
import Seo from "../components/seo"
import { Helmet } from 'react-helmet'
import 'lazysizes'

const FlutterAppDevelopment = () => {
  return (
    <Layout>
        <Helmet>
          <meta name="keywords" content="web development, ecommerce development, mobile app development" />
          <link rel="canonical" href="https://ewisesolutions.com/flutter-app-development-services" />
          <link rel="icon" href={FaviconIcon} />
        </Helmet>
		<Seo title="Flutter App Development Services" description='eWise - Flutter App Development Services'/>
 <section className="relative desktop_version pt-24 pb-24 lg:pt-24 lg:pb-24 xl:pt-24 xl:pb-24 2xl:pt-24 2xl:pb-24">
        <div className="container">
            <div className="-mx-4">
                <div className="banner_section flex flex-wrap items-center min-h-82 sm:min-h-82 md:min-h-82 lg:min-h-93 xl:min-h-97 2xl:min-h-97  flex-col lg:flex-row justify-center">
                    <div className="order-2 sm:order-1 left_banner w-full lg:w-1/2 px-4">
                        <div className="inner text-center lg:text-left">
                            <div className="">
                                <h1 className=" font-normal relative"><strong className="text-50 md:text-55 lg:text-6xl xl:text-70 2xl:text-90 block">Flutter App</strong> <span className="relative top-m-10 md:top-m-10 lg:top-m-15 2xl:top-m-37 inline-block md:pl-0 xl:pl-10 2xl:pl-14 text-gray text-2xl md:text-3xl lg:text-34 xl:text-34 2xl:text-50">Development Services</span> <span className="hidden xl:inline-block xl:left-m-32 2xl:left-m-40 xl:top-m15 2xl:top-m32 circle bg-blue absolute rounded-full z-1 w-10 h-10 sm:w-10 sm:h-10 md:w-10 md:h-10 lg:w-12 lg:h-12 xl:w-14 xl:h-14 2xl:w-74 2xl:h-74"></span></h1>
                            </div>
                        </div>
                    </div>
                    <div className="mt-10 lg:mt-0 order-2 sm:order-2 right_banner w-full lg:w-1/2 px-4 sm:pt-0">
                        <div className="inner relative xl:p-12 2xl:p-0">
                            <img className="w-full sm:w-auto max-w-400 md:max-w-420 lg:max-w-530 m-auto lazyload" loading="lazy" data-src={FlutterAppDevelopmentServiceBanner} alt="Banner"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img className="absolute right-0 top-m112 xl:top-0 z-1 max-w-120 md:max-w-150 lg:max-w-180 xl:max-w-220 2xl:max-w-full" src={AndroidServiceShapeone} alt="Banner"/>
        <img className="absolute left-0 top-m112 xl:top-0 z-1 max-w-120 md:max-w-150 lg:max-w-180 xl:max-w-220 2xl:max-w-full" src={AndroidServiceShapeTwo} alt="Banner"/>
    </section>
  <section className="relative">
      <div className="container">
          <div className="-mx-4">
            <div className="text_content pb-10 md:pb-12 lg:pb-16 xl:pb-24 px-4 xl:pr-16 2xl:pr-32">
                <h4 className="pb-4 xl:pb-6 text-2xl sm:text-26 lg:text-28 xl:text-35 2xl:text-40 ">A single codebase for your mobile, mobile and desktop <br/> devices!<span className="ml-6  hidden xl:inline-block w-m100 bg-pink h-0.8 mb-2"></span></h4>
                <p>Get a great mobile application experience on various sizes and types of devices, just through a single code! No need to create different code permutations to enable your users best view on each device. Flutter is the cross platform app development, open-source UI software that lets you build a user-friendly mobile application with one single code! Now you don’t have to worry about creating separate codes for iOS and Android, Flutter has got you covered!</p>
            </div>

          </div>
      </div>
  </section>
  <section className="relative pb-14">
      <div className="container">
          <div className="-mx-4">
            <div className="features_section flex flex-wrap pb-8 sm:pb-10 lg:pb-14">
                <div className="text_content w-full">
                    <h4 className="pb-4 xl:pb-6 px-4 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">What eWise has to offer through Flutter<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
                </div>
                <ul className="list_features px-4">
                    <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">One code is shared by all the target platform</li>
                    <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">It offers extensive customization of UI for maximum creativity</li>
                    <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Reduced code development time, as only one code has to be developed</li>
                    <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Faster to-market time as it takes twice less time to develop the code</li>
                    <li className="pl-10 relative">Get the performance similar to the Native app as application is built directly in the machine code</li>
                </ul>
            </div>
            <div className="features_section flex flex-wrap pb-8 sm:pb-10 lg:pb-14">
                <div className="features_left w-full lg:w-1/2 px-4 order-2 lg:order-1">
                   
                    <h4 className="pb-4 xl:pb-6 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">What you can achieve through <br className="hidden xl:inline-block"/> Flutter<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>

                    <div className="features_list_left w-full pb-6">
                        <h5 className="pb-4 text-lg md:text-xl lg:text-22">Quick Development</h5>
                        <ul className="list_features">
                            <li className="pl-10 relative mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4">Visualize and bring your mobile app to life within a few seconds with Stateful Hot Reload.</li>
                            <li className="pl-10 relative">Utilize extensive fully-customizable widgets to develop native UI in minutes</li>
                        </ul>
                    </div>
                    <div className="features_list_left w-full pb-6">
                        <h5 className="pb-4 text-lg md:text-xl lg:text-22">Creative and Agile UI</h5>
                        <ul className="list_features">
                            <li className=" pl-10 relative mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4">Get all the features you need to achieve native end-user experiences</li>
                            <li className=" pl-10 relative mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4">Layered architecture enables for exhaustive customization</li>
                            <li className=" pl-10 relative">Enable speedy rendering with amazingly expressive designs</li>
                        </ul>
                    </div>
    
                    <div className="features_list_left w-full ">
                        <h5 className="pb-4 text-lg md:text-xl lg:text-22">Native Performance</h5>
                        <ul className="list_features">
                            <li className="pl-10 relative mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4">Flutter’s widgets integrate all critical platform differences including scrolling, navigation, icons,etc...</li>
                            <li className="pl-10 relative">Flutter code is compiled to native ARM machine code using Dart's native compilers</li>
                        </ul>
                    </div>
                </div>
                <div className="features_right w-full lg:w-1/2 flex justify-center px-4 order-1 lg:order-2">
                    <img className="object-contain max-w-330 md:max-w-420 xl:max-w-530 mb-5 lg:mb-0 w-full lazyload" loading="lazy" data-src={FlutterAppDevelopmentServiceFeature} alt="iphone"/>
                </div>
            </div>
            <div className="features_section flex flex-wrap pb-8 sm:pb-10 lg:pb-14">
                <div className="text_content w-full">
                    <h4 className="pb-4 xl:pb-6 px-4 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">eWise can offer you these Flutter services<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">App Consultation</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">Thinking Flutter as a mobile application platform for your business? We can consult and guide you to develop the right kind of application to serve your purpose.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">UI/UX Design</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">eWise can create creative and effective user interfaces and experience for you. Our dedicated design experts make sure we achieve the design you need.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">App Integration</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">Let us help you to seamless interaction of your multiple applications and modules. We can integrate Flutter apps into various business applications to increase efficiency.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">App Development</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">eWise can develop an application for you from scratch. Our in-house experts are adept with Flutter technology and develop the best apps in the market.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4 pb-6 md:pb-0 ">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Widget Development</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">Already have a Flutter application and need a particular feature to amplify your user experience? We will do it and integrate it seamlessly with your mobile application.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full md:w-1/2 px-4">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Maintenance</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">We can help you to support and maintain your Flutter mobile applications. Keep your focus on your business, leave your technical issues to us!</li>
                    </ul>
                </div>
            </div>
          </div>
      </div>
      <img className="absolute right-0 bottom-1/4 z-1 max-w-150 md:max-w-180 lg:max-w-200 xl:max-w-250 2xl:max-w-320" src={ServiceCircleShape} alt="Service Circle"/>
  </section>
    </Layout>
  )
}

export default FlutterAppDevelopment